.results-wrapper {
  .results {
    border: 1px solid #dee2e6;
    margin-left: 53px;
    margin-right: 10px;
    margin-top: -1px;
    border-radius: 0 0 8px 8px;
    background-color: #f8f9fa;

    li {

      font-weight: 600;
      font-size: 1.1em;

      //cursor: pointer;

      &.active {
        background-color: darken(#f8f9fa, 10%);
      }

      a {
        display: block;
        padding: 8px 15px;
        //border: 1px solid;
        color: #000;
        text-decoration: none;
        //margin-top: 3px;
        //margin-bottom: 3px;
      }
    }
  }

  p {
    margin-left: 53px;
    padding: 10px 15px 0 15px;
    color: darken(#dee2e6, 40%);
  }
}
