@import "src/styles/variables";


.index-tile {
  border: 1px solid $ts-grey;
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;
  margin-bottom: 10px;
  width: 100%;

  &:hover {
    background-color: $ts-grey;
    box-shadow: 0 .125rem .25rem rgba(#000, .075);;
  }


  .index-name {
    display: block;
    font-weight: bold;
  }

  .index-count {
    font-style: italic;
    font-size: small;
  }

  &.active {
    background-color: $ts-primary;
    border-color: $ts-primary;
    color: #fff;
  }
}
