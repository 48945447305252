@import "../../../styles/variables";

.company-tile {
  border: 1px solid $ts-grey;
  border-radius: 5px;

  &:hover {
    border-color: $primary;
    box-shadow: 0 .125rem .25rem rgba(#000, .075);;
  }

  a {
    text-decoration: none;
    color: $ts-primary;

    .img-thumbnail {
      border: none;
      max-height: 100%;
      width: auto;
    }
  }

  .logo-wrapper {
    width: 100%;
    height: 140px;
    text-align: center;

  }

  .title-wrapper {
    position: relative;
    min-height: 109px;
    //border: 1px solid;
    padding-bottom: 28px;
  }

  .company-name {
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
    color: $ts-primary;
    //border: 1px dashed;

  }

  .ticker-badge {
    position: absolute;
    bottom: 0;
    left: 0;
    display: inline-block;
    font-weight: normal;
    font-size: 0.7em;
    border: 1px solid $primary;
    padding: 2px 6px;
    border-radius: 4px;
  }

  .index-count {
    font-style: italic;
    font-size: small;
  }

  .index-name {
    font-weight: bold;
    color: #4d5154;
    font-size: 1.1em;
  }
}
