.footer-wrapper {
  font-weight: normal;

  .ts-logo-wrapper {
    img {
      width: auto;
      height: 50px;
    }
  }

  .site-links {
    font-size: 0.9em;
    a {
      color: #212529;
      text-decoration: none;
    }
  }

  .social-links {
    a {
      font-size: 1.5em;
      color: #212529;
    }
  }
}
