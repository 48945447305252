.navigation-main-wrapper {
  .ts-logo-wrapper {
    height: 40px;
    width: 150px;
    position: relative;

    img {
      top: -2px;
      left: -5px;
      position: absolute;
      height: 60px;
      width: auto;
    }
  }

  .nav-wrapper {
    //border: 1px solid;

  }

  .btn-search {
    color: #2b2f32;
    padding: 1px 3px 3px 3px;
    font-size: 22px;
    //border: 1px solid;
  }
}

