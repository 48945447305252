.app-container {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;

  .page-content {
    flex: 1;
  }

  .navigation-wrapper {
    padding: 0;
  }

  .page-wrapper {
    padding: 15px;
  }

  .navigation-wrapper,
  .page-footer {
    flex-shrink: 0;
    background-color: #f8f9fa;
  }
}





