@import "../../../styles/variables";

.cont-comp {
  border: 1px solid;
}

.logo-wrapper {
  .img-thumbnail {
    border: none;
  }
}


.page-wrapper {
  .company-title-wrapper {
    position: relative;
    display: inline-block;
    padding-right: 40px;

    h2.company-title-element {
      margin-bottom: 20px;


      span {
        display: inline-block;
        vertical-align: middle;
      }

      .ticker-badge {
        font-size: 0.5em;
        font-weight: 400;
        color: $primary;
        padding: 5px 12px;
        border-radius: 4px;
        background-color: #fff;
        border: 1px solid $primary;
      }
    }
  }
}
