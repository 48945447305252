@import "src/styles/variables";

.group-wrapper {

  h3 {
    .group-name {
      font-weight: 600;
      font-style: italic;
    }
  }

  .industries-wrapper {
    max-height: 850px;
    overflow: hidden;
    overflow-y: scroll;
    padding: 0 15px 0 0;


    li {
      display: block;
      border: 1px solid $ts-grey;
      margin-bottom: 5px;
      border-radius: 5px;
      padding: 0;

      &:hover {
        background-color: $ts-grey;
        box-shadow: 0 .125rem .25rem rgba(#000, .075);;
      }

      &.active {
        background-color: $ts-primary;
        color: #fff;
        border-color: $ts-primary;

        a {
          color: #fff;
        }
      }

      a {
        display: block;
        padding: 5px 10px;
        text-decoration: none;
      }
    }
  }
}
